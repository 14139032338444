
export async function getDocumentFolders(userid, parentID, appid) {
    var url;
    if (parentID) {
        url = 'document/getfolders?userid=' + userid + '&parentid=' + userid + '&appid=' + appid;
    }
    else {
        url = 'document/getfolders?appid=' + appid;
    }
    url = 'document/getfolders?appid=LS';
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getFolder(id){
    const response = await fetch('document/getfolder?id=' + id);
    if (response.status === 200) {
        const data = await response.json();
        if (data)
            return data;
    }
        return null;
}
export async function getDocument(id){
    const response = await fetch('document/getdocument?id=' + id);
    if (response.status === 200) {
        const data = await response.json();
        if (data)
            return data;
    }
        return null;
}

export async function downloadDocument(id) {
    const response = await fetch('document/downloaddocument?id=' + id);
    if (response.status === 200) {
        const data = await response.blob();
        console.log(data);
        if (data) {
            const fileURL = window.URL.createObjectURL(data);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.target = "_blank";
            alink.click();
        }

    }
    return null;
}
export async function getDocumentInfo(id) {
    const response = await fetch('document/getdocumentInfo?id=' + id);
    if (response.status === 200) {
        const data = await response.json();
        if (data)
            return data;
    }
    return null;
}

export async function newDocument(file, parentID, appid){
    const fileName = file.name;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('parentid', parentID);
    formData.append('appid', appid);

    const doc = await fetch('document/uploadfile', {
        method: 'POST',
        body: formData
    }).then(
        response => response.json()
    ).then(
        response => {
            var newID = response;
            var doc = { documentID: newID, documentName: fileName, createDate: new Date().toISOString() };
            return doc;
        }
    ).catch((error) => {
        console.log(error);
        return null;
    });
    return doc;
}
export async function newOwnerDocument(file, ownerid, parentID, appid) {
    const fileName = file.name;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('parentid', parentID);
    formData.append('userid', ownerid);
    formData.append('appid', appid);

    const doc = await fetch('document/uploadownerfile', {
        method: 'POST',
        body: formData
    }).then(
        response => response.json()
    ).then(
        response => {
            var newID = response;
            var doc = { documentID: newID, documentName: fileName, createDate: new Date().toISOString() };
            return doc;
        }
    ).catch((error) => {
        console.log(error);
        return null;
    });
    return doc;
}

export async function deleteDocument(id) {
    const req = { DocID: id };
    const response = await fetch("document/deletedocument", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}

export async function renameDocument(id, name) {
    const req = { DocID: id, Name: name };
    const response = await fetch("document/editdocument", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function moveDocument(id, parentid) {
    const req = { DocID: id, ParentID: parentid };
    const response = await fetch("document/movedocument", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function newFolder(name, parentID, appid) {
    const req = { Name: name, ParentID: parentID, AppID: appid };
    const response = await fetch("document/createfolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        if (data) {
            const folder = { id: data, name: name, documents: [] };
            return folder;
        }
    }
    return false;
}
export async function newOwnerFolder(name, userid, parentID, appid) {
    const req = { Name: name, ParentID: parentID, AppID: appid, UserID: userid };
    const response = await fetch("document/createownerfolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        if (data) {
            const folder = { id: data, name: name, documents: [] };
            return folder;
        }
    }
    return false;
}

export async function deleteFolder(id) {
    const req = { FolderID: id };
    const response = await fetch("document/deletefolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}

export async function renameFolder(id, name) {
    const req = { FolderID: id, Name: name };
    const response = await fetch("document/editfolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}

export async function shareDocument(id, ownerid, userid, isFolder, appid, canedit, hasexpiration, expiration) {
    const req = {
        DocID: id,
        OwnerID: ownerid,
        UserId: userid,
        IsFolder: isFolder,
        AppID: appid,
        CanEdit: canedit,
        HasExpiration: hasexpiration,
        Expiration: expiration
    };
    const response = await fetch("document/sharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unShareDocument(id, ownerid, userid, appid) {
    const req = {
        DocID: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("document/unsharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function hideSharedDocument(id, ownerid, userid, appid) {
    const req = {
        DocID: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("document/hidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unhideSharedDocument(id, ownerid, userid, appid) {
    const req = {
        DocID: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("document/unhidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}